// https://css-tricks.com/flexbox-truncated-text/#article-header-id-3
.min-w-0
  min-width: 0 !important

@each $breakpoint in map-keys($grid-breakpoints)
  @include media-breakpoint-up($breakpoint)
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints)

    @each $prop, $abbrev in (width: w, height: h)
      @each $size, $length in $sizes
        .#{$abbrev}#{$infix}-#{$size}
          #{$prop}: $length !important
