[class^="icon--"], [class*=" icon--"]
  position: relative
  display: inline-block
  width: 1em
  height: 1em
  fill: currentColor
  line-height: 1
  vertical-align: middle

.icon--svg
  display: block
  width: 100%
  height: 100%
  background: inherit
  fill: inherit
  pointer-events: none // Do not catch pointer events
  -ms-transform: translate(0.5px, -0.3px) // Crisp fix for IE
  transform: translateX(0) // Crisp fix for non-retina

.rotate-180
  transform: rotate(180deg)
