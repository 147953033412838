.ports-group
  $item-border-radius: 3px

  display: flex
  position: relative

  @include media-breakpoint-up(lg)
    display: block
    flex: 0 0 auto
    width: 20%

    &.has-two
      width: 40%
    &.has-three
      width: 60%
    &.has-four
      width: 80%

  &.is-separated
    border: 0
    border-radius: $item-border-radius
    margin-top: 10px

    @include media-breakpoint-up(lg)
      margin-top: 0
      padding-left: 10px

  &-items,
  &-note
    @include media-breakpoint-down(md)
      flex: 1
      width: 50%
      display: flex
      flex-direction: column

  &-items
    @include media-breakpoint-up(lg)
      display: flex

  &-item
    display: flex
    position: relative
    flex: 1
    padding: 6px 10px 5px 25px
    border-bottom: 1px solid #fff
    border-radius: $item-border-radius
    text-align: center
    font-size: 12px
    color: #fff
    justify-content: flex-end
    align-items: center

    @include media-breakpoint-up(lg)
      display: block
      min-width: 65px
      min-height: 73px
      border-left: 1px solid #fff
      padding: 7px

    @include media-breakpoint-up(lg)
      &:first-child
        border-top-left-radius: $item-border-radius
        border-bottom-left-radius: $item-border-radius

      &:last-child
        border-top-right-radius: $item-border-radius
        border-bottom-right-radius: $item-border-radius

    &.lan
      background-color: #7fa1c3
      .i2cm &
        background-color: #2a6190

    &.wan
      background-color: #012552
      .i2cm &
        background-color: #8d629a

    &.crew-lan
      background-color: #2a6190
      .i2cm &
        background-color: #7fa1c3

    &.is-disabled
      .icon--socket,
      .icon--wifi
        &::after
          position: absolute
          bottom: -1px
          right: -5px
          width: 14px
          height: 14px
          background: url('../icons/disabled.svg')
          background-size: 100%
          content: ''

          @include media-breakpoint-up(lg)
            bottom: -3px
            right: -2px
            width: 18px
            height: 18px

    .icon--socket,
    .icon--wifi
      display: block
      position: relative
      margin-right: 10px
      width: 26px
      height: 17px
      order: -1

      @include media-breakpoint-up(lg)
        margin: 2px auto 0
        width: 39px
        height: 28px

    .icon--lock-locked
      position: absolute
      bottom: 7px
      left: 7px
      color: #fff

    .item-text
      min-width: 32px

  &-note
    display: flex
    position: relative
    font-size: 12px

    @include media-breakpoint-up(lg)
      display: block
      padding: 8px 6px 0
      text-align: center

      &::before
        display: block
        position: absolute
        top: 0
        right: -1px
        left: 0
        height: 17px
        border: dashed #ccc
        border-width: 0px 1px 1px 1px
        border-radius: 0 0 $item-border-radius $item-border-radius
        content: ''

        .ports-group:last-child &
          right: 0

    .note-text
      @include media-breakpoint-down(md)
        display: flex
        align-items: center
        border: 1px dashed #ccc
        border-width: 1px 1px 1px 0
        border-radius: 0  $item-border-radius  $item-border-radius 0
        margin-top: -1px
        padding: 7px 10px 4px
        flex: 1

        .ports-group:first-child &,
        .is-separated &
          margin-top: 0

      @include media-breakpoint-up(lg)
        position: relative
        padding-left: 3px
        padding-right: 3px
        background-color: #fff
        z-index: 1
