@each $size, $length in $spacers
  .border-width-#{$size}
    border-width: $length !important

.rounded-sm
  border-radius: $utility-border-radius-sm !important

.rounded
  border-radius: $utility-border-radius !important

.rounded-lg
  border-radius: $utility-border-radius-lg !important
