@mixin make-btn-color-states($color, $hover-color, $active-color)
  color: $color
  &:hover
    color: $hover-color
  &.active,
  &:active,
  &:focus
    color: $active-color

@mixin make-btn-shadow-states($shadow, $hover-shadow: $shadow, $active-shadow: $shadow)
  box-shadow: $shadow
  &:hover
    box-shadow: $hover-shadow
  &.active,
  &:active,
  &:focus
    box-shadow: $active-shadow

.btn-white-shadow
  @include gradient-bg($white)
  @include make-btn-color-states($body-color, $link-color, $link-color)
  @include make-btn-shadow-states($shadow: $btn-white-shadow, $hover-shadow: $btn-white-hover-shadow, $active-shadow: $btn-white-hover-shadow)

.btn-icon
  display: inline-flex
  align-items: center
  justify-content: center
  min-height: 1.5em
  .icon
    display: inline-block
    min-width: $btn-icon-min-width

.btn.active
  &.active,
  &:active,
  &:focus
    color: $link-color
