.nav-tabs.nav-tabs-sm
  .nav-link
    $nav-link-padding-y: $nav-link-padding-y * $nav-tabs-sm-padding
    $nav-link-padding-x: $nav-link-padding-x * $nav-tabs-sm-padding
    padding: $nav-link-padding-y $nav-link-padding-x
    @include font-size($nav-tabs-sm-font-size)

.nav-tabs.nav-tabs-light
  .nav-link
    margin-left: $nav-tabs-light-gutter
    color: $nav-tabs-light-link-color
    background-color: $nav-tabs-light-link-bg
    font-weight: 600
    font-size: 15px !important
    padding: 11px 25px
    display: flex
    @include media-breakpoint-down(sm)
      margin-left: 14px
    @include media-breakpoint-down(xs)
      margin-left: 10px
  .nav-link.active
    color: $gray-800
    background-color: $nav-tabs-light-link-active-bg
    border-bottom-color: $nav-tabs-light-link-active-bg
  .nav-item:first-of-type
    .nav-link
      @include media-breakpoint-up(sm)
        margin-left: 0

  .nav-link.nav-item:first-of-type
    @include media-breakpoint-up(sm)
      margin-left: 0
