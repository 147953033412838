.authFormContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  background-image: url(../../style/images/login-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.authForm {
  max-width: 950px;
  width: auto;
  min-width: 50px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 60px;
  padding-right: 60px;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 17px;
  text-justify: center;
}
