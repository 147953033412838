@each $name, $color in $colors
  .bg-#{$name}
    background-color: $color !important

  .border-#{$name}
    border-color: $color !important

  .text-#{$name}
    color: $color !important

@each $alpha in $colors-alpha-variants
  @each $name, $color in $colors
    .bg-a-#{$alpha * 100}-#{$name}
      background-color: rgba($color, $alpha) !important
