.contentbox {
  max-width: 1050px;
  width: auto;
  min-width: 50px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  margin: 50px;
  padding: 24px!important;
  background-color: rgb(255, 255, 255);
  font-size: 17px;
}

@media (max-width: 991px) {
  .contentbox {
    margin: 0px!important;
    padding: 16px!important;
    box-shadow: none;
  }
}
