.progress-lg
  height: $progress-lg-height
  font-size: $progress-lg-font-size

.progress-bar
  &.rounded-left
    border-top-left-radius: $progress-border-radius !important
    border-bottom-left-radius: $progress-border-radius !important

  &.rounded-right
    border-top-right-radius: $progress-border-radius !important
    border-bottom-right-radius: $progress-border-radius !important

  .by-right
    transform: translate(100%, 0)

  &.bg-50
    background-color: $progress-bg-color-50 !important

  &.bg-80
    background-image: linear-gradient(to right, $progress-bg-color-50 50%, $progress-bg-color-68 68%, $progress-bg-color-76 76%, $progress-bg-color-83 83%) !important

  &.bg-100
    background-image: linear-gradient(to right, $progress-bg-color-50 50%, $progress-bg-color-68 68%, $progress-bg-color-76 76%, $progress-bg-color-83 83%, $progress-bg-color-100 100%) !important

  &.bg-orange
    background-color: $progress-bg-color-100 !important

  &.bg-gray
    background-color: $progress-bg-color-gray !important

.progress-custom-label
  font-weight: bold
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25), -1px -1px 1px rgba(0, 0, 0, .1)
  padding: 0 .5rem

.progress-threshold
  position: absolute
  bottom: 0
  height: 100%
  line-height: $line-height-base

  @each $threshold in (50, 80, 100)
    &.threshold-#{$threshold}
      right: percentage(1 - calc($threshold/100))
      @if $threshold == 100
        .threshold-label
          transform: translateX(0)
        &:after
          content: none

  .threshold-label
    position: absolute
    bottom: 100%
    right: 0
    transform: translateX(50%)
    font-weight: bold
    @include font-size($small-font-size)
    color: $gray-500

  &:after
    content: ''
    display: block
    width: 1px
    height: 100%
    background-color: rgba($white, 0.6)
