body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.invalid-feedback {
  color: #ff0000!important;
}

.Nav-item {
  background-color: rgb(24, 47, 52);
}

.dropdown-menu.show{
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
}

.nav-dropdown>a::after {
  color: white;
}

.bold-text {
  font-weight: bold;
}

.data-table-label {
  width: 246px;
  max-width: 246px;
  color: rgb(120, 135, 149);
  background: rgb(0, 0, 0, 0.03125);
}

.data-table-value {
  width: 246px;
  max-width: 246px;
}

.navbar ul {
  display: none;
  padding: 0px;
  margin: 0px;
  background-color: #343a41;
  position: absolute;
  top: 0;
  left: 0;
  max-width: '340px';
  width: '90%';
  font-size: '17px';
  list-style: none
}

/* Toast Styles Start */

/* Move toast below top banner */
.Toastify__toast-container--top-right {
  top: 74px;
}

.Toastify__toast {
  border-radius: 0px;
  margin-bottom: 8px
}

.Toastify__toast--info {
  background-color: #1A84C7 !important;
}

.Toastify__toast--success {
  background-color: #219245 !important;
}

.Toastify__toast--warning {
  background-color: #F08521 !important;
}

.Toastify__toast--error {
  background-color: #cc0000 !important;
}
