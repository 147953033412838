.content
  display: flex
  flex-direction: column
  flex: 1 1 100%
  max-width: 100%

.content-frame
  display: flex
  flex-direction: column
  flex: 1 1 auto
  height: 0
  padding: $content-frame-padding-y $content-frame-padding-x
  overflow-y: auto

  @include media-breakpoint-down(sm)
    padding: $content-frame-padding-y-sm $content-frame-padding-x-sm

.content-space
  padding: $content-space-padding-y $content-space-padding-x

  @include media-breakpoint-down(xs)
    padding: $content-space-padding-y-xs $content-space-padding-x-xs

  @include media-breakpoint-up(lg)
    padding: $content-space-padding-y-lg $content-space-padding-x-lg

.content-bg-color
  background-color: $content-bg-color

.content-header
  display: flex
  margin-bottom: $content-header-margin-bottom
  align-items: center
  justify-content: space-between
  flex-wrap: wrap
