@mixin make-sidebar($width: $sidebar-width, $min-width: $sidebar-min-width, $bg-color: $sidebar-bg-color, $border-width: $sidebar-border-width, $border-color: $sidebar-border-color)
  display: flex
  flex-direction: column
  flex-basis: $width
  min-width: $min-width
  border-right: $border-width solid $border-color
  background-color: $bg-color

@mixin make-responsive-sidebar($breakpoint-down: $sidebar-breakpoint-down)
  @include media-breakpoint-down($breakpoint-down)
    flex-basis: 100%
