@mixin make-menu-hide()
  position: absolute
  top: $navbar-fixed-height
  left: 0
  right: 0
  bottom: 0
  border-right: none
  transform: translateX(-100%)
  z-index: $menu-z-index
  @include media-breakpoint-down($menu-breakpoint-down)
    transition: transform .3s

.menu
  // TODO: align menu look and constant to new design
  // $menu-width, $menu-min-width, $menu-bg-color, $menu-border-width, $menu-border-color
  background-color: $menu-bg-color
  @include make-menu-hide()

  &.show
    transform: translateX(0)
