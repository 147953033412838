.list-group-flush-all
  @include border-radius(0)

  > .list-group-item
    border-width: 0

    &:last-child
      border-bottom-width: 0

.list-group-custom-active
  .list-group-item.active
    font-weight: 700
    background-color: transparent
    border-color: $list-group-border-color
    color: $body-color

  .list-group-item:hover
    color: $primary

.list-group-responsive
  @extend .list-group-custom-active

  background: $white
  @include border-radius(0)

  @include media-breakpoint-up(md)
    border: none
    background: none
    padding: 0.25rem 0

  > .list-group-item
    border-right-width: 0
    border-left-width: 0
    line-height: 2rem
    padding: 0.5rem 1rem

    &:first-child
      border-top-width: 0

    @include media-breakpoint-up(md)
      border: none
      background: none
      color: $body-color
      line-height: 1.2rem
      padding: 0.5rem
