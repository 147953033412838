.navrail-wrapper
  display: flex
  flex: 1

  @include media-breakpoint-down($navrail-breakpoint-down)
    flex-direction: column-reverse

.navrail
  display: flex
  background: $navrail-bg
  width: $navrail-width
  flex-direction: column

  @include media-breakpoint-down($navrail-breakpoint-down)
    width: 100%

  &-frame
    display: flex
    flex-direction: column
    flex: 1 1 auto
    height: 0
    overflow-y: auto

    @include media-breakpoint-down($navrail-breakpoint-down)
      flex-direction: row
      height: auto
      overflow-y: initial

  &-link
    padding: 1rem 0.25rem 0.875rem
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    text-align: center
    color: $navrail-item-color
    font-size: $navrail-item-font-size
    line-height: $navrail-item-line-height
    font-weight: $navrail-item-font-weight

    .icon
      width: 100%
      height: 2.5rem

      @include media-breakpoint-down($navrail-breakpoint-down)
        height: 1.5rem

    @include media-breakpoint-down($navrail-breakpoint-down)
      padding: 0.5rem 0.5rem 0.375rem
      flex: auto

    &:hover
      background: $navrail-item-hover-bg
      color: $navrail-item-hover-color
      text-decoration: none

    &.active
      background: $navrail-item-active-bg
      color: $navrail-item-active-color

      @include media-breakpoint-down($navrail-breakpoint-down)
        background: inherit
        color: $white

  &-label
    padding-top: map-get($spacers, 2)
