.printVoucherContainer {
  background-color: #FFFFFF;
  max-width: 1000px;
}

.printVoucher {
  width: 282px;
  height: 222px;
  border: 1px solid #E4E6EC;
  background-color: #FFFFFF;
  color: #0C203C;
  display: inline-block;
}

.printVoucher .voucherHeader {
  width: 280px;
  height: 36px;
  background-color: #CC0000;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.printVoucher .voucherTitle {
  width: 280px;
  height: 48px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  word-break: break-word;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.printVoucher .voucherTitleSm {
  font-size: 20px;
}

.printVoucher .voucherBody {
  width: 280px;
  height: 108px;
  font-size: 12px;
  font-weight: bold;
  padding-top: 2px;
}

.printVoucher .voucherBody .voucherColTitle {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.printVoucher .voucherBody .voucherTable {
  margin: 0px;
  color: #0C203C;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.5;
}

.printVoucher .voucherFooter {
  width: 280px;
  height: 24px;
  font-size: 9px;
  text-align: center;
  line-height: 1.1;
  padding-top: 4px;
}
