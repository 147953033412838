$rightArrow: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23718190'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
$rightArrowWhite: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
$rightArrowBlack: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")

.accordion
  .control-btn
    text-decoration: none!important
    padding: 0.75rem
    display: flex
    align-items: center

  .control-btn.arrow-at-start:before, .control-btn.arrow-at-end:after
    flex-shrink: 0
    width: 1.25rem
    height: 1.25rem
    margin-right: 0.75rem
    margin-left: 0.75rem
    content: ""
    background-image: $rightArrow
    background-repeat: no-repeat
    background-size: 1.25rem
    transition: transform 0.2s ease-in-out

  .control-btn.arrow-at-start.arrow-white:before, .control-btn.arrow-at-end.arrow-white:after
    background-image: $rightArrowWhite

  .control-btn.arrow-at-start.arrow-black:before, .control-btn.arrow-at-end.arrow-black:after
    background-image: $rightArrowBlack

  .control-btn.arrow-at-end:after
    right: 0
    position: absolute

  .control-btn.arrow-at-start:before, .control-btn.arrow-at-end:after
    transform: rotate(-90deg)

  .control-btn.arrow-at-start:not(.open):before, .control-btn.arrow-at-end:not(.open):after
    -webkit-transform: rotate(-180deg)
    transform: rotate(0deg)

