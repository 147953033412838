.summaryTable {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -16px 0px -16px;
  padding: 32px 0px 16px 0px;
}

.summaryTableCol {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
}
