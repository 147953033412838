@each $name, $color in $colors
  .tooltip-#{$name}
    &.tooltip .tooltip-inner
      background-color: $color

    &.bs-tooltip-top
      .arrow::before
        border-top-color: $color

    &.bs-tooltip-bottom
      .arrow::before
        border-bottom-color: $color

    &.bs-tooltip-left
      .arrow::before
        border-left-color: $color

    &.bs-tooltip-right
      .arrow::before
        border-right-color: $color
  
