label
  font-weight: $label-font-weight

.custom-select
  option
    padding: $custom-select-option-padding-y $custom-select-option-padding-x

$indicator-size: 1.3rem
$switch-control-size: 1rem
$radio-custom-control-indicator-size: 1.5rem !default
$custom-control-gutter: 0.5rem !default

// https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
input.form-control.form-control-ip
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    display: none
    -webkit-appearance: none
    margin: 0

  -moz-appearance: textfield

  padding-left: 0.15rem
  padding-right: 0.15rem
  min-width: 2.3rem
  flex-grow: 1
  flex-basis: 0

  text-align: center

  &.is-valid, &.is-invalid
    background-image: none

.custom-switch
  &.custom-switch-material
    .custom-control-label
      cursor: pointer

      &::before
        height: $switch-control-size
        width: $indicator-size * 1.75
        border-radius: 2rem
        border: 0px
        background-color: $gray-400
        top: ($font-size-base * $line-height-base - $switch-control-size) * 0.5

      &::after
        width: $indicator-size
        height: $indicator-size
        border-radius: 50%
        background-color: #fff
        box-shadow: 0 2px 10px #aaa
        top: -0.4rem
        left: -4rem
        top: ($font-size-base * $line-height-base - $indicator-size) * 0.5

    .custom-control-input
      &:checked
        ~ .custom-control-label::after
            transform: translateX(calc(1rem))
            background-color: theme-color('primary')

        ~ .custom-control-label::before
            background-color: rgba(theme-color('primary'), 0.5)

      &[disabled], &:disabled
        ~ .custom-control-label
            color: $custom-control-label-disabled-color

            &::before
              background-color: $custom-control-indicator-disabled-bg

      &:focus, &:active
        ~ .custom-control-label
          &::after
            box-shadow: 0 0 0px 4px rgba(theme-color('primary'), 0.2)

      &:focus:not(:checked), &:active:not(:checked)
        ~ .custom-control-label
          &::after
            box-shadow: 0 0 0px 4px rgba($gray-400, 0.3)
          &::before
            background-color: $gray-400
.form-control
  &::placeholder
    opacity: 0.5

.custom-control.custom-radio, .custom-control.custom-checkbox
  .custom-control-label
    &::before
      top: ($font-size-base * $line-height-base - $radio-custom-control-indicator-size) * .5
      left: -($custom-control-gutter + $radio-custom-control-indicator-size)
      width: $radio-custom-control-indicator-size
      height: $radio-custom-control-indicator-size

    &::after
      top: ($font-size-base * $line-height-base - $radio-custom-control-indicator-size) * .5
      left: -($custom-control-gutter + $radio-custom-control-indicator-size)
      width: $radio-custom-control-indicator-size
      height: $radio-custom-control-indicator-size

.custom-control.custom-checkbox
  .custom-control-label
    &::after
      background: 50%/70% no-repeat
