@mixin hamburger-variant($color)
  display: flex
  align-items: center
  justify-content: center
  padding: $hamburger-padding-y $hamburger-padding-x
  transition-timing-function: linear
  transition-duration: 0.15s
  transition-property: opacity,filter
  color: inherit
  cursor: pointer

  &-toggler
    display: none

  &-box
    position: relative
    display: inline-block
    width: $hamburger-width
    height: ($hamburger-line-height + $hamburger-line-space) * 3

  &-inner,
  &-inner:after,
  &-inner:before
    position: absolute
    width: $hamburger-width
    height: $hamburger-line-height
    transition-timing-function: ease
    transition-duration: 0.15s
    transition-property: transform
    border-radius: $hamburger-line-height * 2
    background-color: $hamburger-line-color

  &-inner
    top: 50%
    display: block
    margin-top: -$hamburger-line-height
    transition-timing-function: cubic-bezier(.55,.055,.675,.19)
    transition-duration: 75ms

    .active &
      transition-delay: .12s
      transition-timing-function: cubic-bezier(.215,.61,.355,1)
      transform: rotate(45deg)

    &:before,
    &:after
      display: block
      content: ""

    &:before
      top: -($hamburger-line-height + $hamburger-line-space)
      .active &
        top: 0
        transition: top 75ms ease,opacity 75ms ease .12s
        opacity: 0

    &:after
      bottom: -($hamburger-line-height + $hamburger-line-space)
      .active &
        bottom: 0
        transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s
        transform: rotate(-90deg)


.hamburger
  @include hamburger-variant($hamburger-line-color)
