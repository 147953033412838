.status
  @each $name, $color in $status-colors
    &-#{$name}
      color: $color
    &-bg-#{$name}
      background-color: $color
      color: $white

  &-bg-logout
    color: $body-color
