$arrow-height-sm: 0.4rem
$arrow-height: 1rem

.arrow::after
  position: absolute
  content: ""
  width: 0
  height: 0
  border: $arrow-height solid transparent
  pointer-events: none

.arrow.arrow-top-center::after,
.arrow.arrow-top-left::after,
.arrow.arrow-top-right::after
  border-bottom-color: white
  border-top-width: 0
  top: -$arrow-height

.arrow.arrow-bottom-center::after,
.arrow.arrow-bottom-left::after,
.arrow.arrow-bottom-right::after
  border-top-color: white
  border-bottom-width: 0
  bottom: -$arrow-height

.arrow-top-left::after,
.arrow-bottom-left::after
  left: 0

.arrow-top-right::after,
.arrow-bottom-right::after
  right: 0

.arrow-top-center::after,
.arrow-bottom-center::after
  left: calc(50% - #{$arrow-height})

.arrow-sm
  &::after
    border-width: $arrow-height-sm

  &.arrow-top-center::after,
  &.arrow-top-left::after,
  &.arrow-top-right::after
    top: -$arrow-height-sm

  &.arrow-bottom-center::after,
  &.arrow-bottom-left::after,
  &.arrow-bottom-right::after
    bottom: -$arrow-height-sm

  &.arrow-top-center::after,
  &.arrow-bottom-center::after
    left: calc(50% - #{$arrow-height-sm})
