.input-group
  .form-control ~ .input-group-append,
  .form-control ~ .input-group-prepend
    .btn-link, .input-group-text
      border: $input-border-width solid $input-border-color
      background-color: $input-bg
      color: $input-color

  .form-control:focus ~ .input-group-append,
  .form-control:focus ~ .input-group-prepend
    .btn-link, .input-group-text
      border: $input-border-width solid $input-focus-border-color
      background-color: $input-bg

  .form-control ~ .input-group-append
    .btn-link
      border-left: 0

  .form-control ~ .input-group-prepend
    .btn-link
      border-right: 0
