.table
  thead th
    border-bottom: $table-border-width solid $table-border-color

.table-bordered
  thead
    th, td
      border-bottom-width: $table-border-width

.table-borderless
  th,
  td,
  thead th,
  tbody + tbody
    border: 0

.table-layout-fixed
  table-layout: fixed

.table-transposed
  th, td
    word-wrap: break-word
    border-top: 0
    border-right: $table-border-width solid $table-border-color
    border-bottom: $table-border-width solid $table-border-color
    &:last-child
      border-right: 0

  th
    border-right: $table-border-width solid $table-border-color

  tr:last-child
    th, td
      border-bottom: 0

  &.thead-dark
    th
      color: $table-dark-color
      background-color: $table-dark-bg
      border-color: $table-dark-border-color

  &.thead-light
    th
      color: $table-head-color
      background-color: $table-head-bg
      border-color: $table-border-color

  &.table-borderless
    th, td
      border-right: 0
      border-bottom: 0

// https://github.com/iKristjan/bootstrap-responsive-table-scrolling-shadows
.table-responsive
  background: linear-gradient(to right, rgba($table-responsive-scroll-shadow,0.08) 30%, rgba($table-responsive-scroll-shadow,0)), linear-gradient(to left, rgba($table-responsive-scroll-shadow,0.08) 30%, rgba($table-responsive-scroll-shadow,0)) 100% 0, radial-gradient(farthest-side at 0% 50%, rgba($table-responsive-scroll-shadow,.15), rgba($table-responsive-scroll-shadow,0)), radial-gradient(farthest-side at 100% 50%, rgba($table-responsive-scroll-shadow,.15), rgba($table-responsive-scroll-shadow,0)) 100% 0
  background-color: white
  background-repeat: no-repeat
  background-size: 20px 100%
  margin-bottom: 15px

.table-responsive > .table
  background: linear-gradient(to right, white 30%, rgba($table-responsive-scroll-light,0)), linear-gradient(to left, white 30%, rgba($table-responsive-scroll-light,0)) 100% 0
  background-size: 50px 100%
  background-repeat: no-repeat
  max-width: none
  margin-bottom: 0
  min-width: 100%
