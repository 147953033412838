.navbar-fixed-height
  height: $navbar-fixed-height

  @include media-breakpoint-down($navrail-breakpoint-down)
    height: $navbar-fixed-height-sm

.navbar-square
  width: $navbar-fixed-height

.navbar-brand
  font-weight: 300
  @include media-breakpoint-down($navrail-breakpoint-down)
    font-size: $navbar-brand-font-size-sm

.navbar-blue
  background-color: $bg-navbar

  .navbar-square
    background-color: $bg-navbar-navy

  .nav-item
    @include hover-focus()
      color: $navbar-blue-hover-color
      background-color: $navbar-blue-nav-item-hover-bg-color
      box-shadow: $navbar-blue-nav-item-hover-box-shadow

    &.dropdown-hover
      .dropdown-menu
        background-color: $navbar-blue-dropdown-menu-bg-color

      .dropdown-item
        color: $navbar-blue-dropdown-item-color
        @include hover-focus()
          color: $navbar-blue-dropdown-item-hover-color
          background-color: $navbar-blue-dropdown-item-bg-color

.navbar-navy
  background-color: $bg-navbar-navy

  .navbar-square
    background-color: $black

  .nav-item
    @include hover-focus()
      color: $navbar-navy-hover-color
      background-color: $navbar-navy-nav-item-hover-bg-color
      box-shadow: $navbar-navy-nav-item-hover-box-shadow

    &.dropdown-hover
      .dropdown-menu
        background-color: $navbar-navy-dropdown-menu-bg-color

      .dropdown-item
        color: $navbar-navy-dropdown-item-color
        @include hover-focus()
          color: $navbar-navy-dropdown-item-hover-color
          background-color: $bg-navbar-navy
