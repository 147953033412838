html,
body
  height: 100%

.page-wrapper
  height: 100%
  min-height: 100%
  display: flex
  flex-direction: column

.main-wrapper
  display: flex
  flex: 1
