$border-radius-inside: 5px
$space-between-buttons: 3px

.btn-group.btn-group-toggle
  background: $secondary
  padding: 0.2rem
  border-radius: $border-radius-inside * 1.4

.btn-group.btn-group-toggle .btn
  border-radius: $border-radius-inside !important
  color: $gray
  margin-right: $space-between-buttons

.btn-group.btn-group-toggle .btn:last-child
  margin-right: 0

.btn-group.btn-group-toggle .btn.active
  border: 0
  background: $white
  color: $primary
  font-weight: 600
