@each $direction in $overflow-directions
  @each $mode in $overflows
    .overflow-#{$direction}-#{$mode}
      overflow-#{$direction}: $mode !important

.overflow-shadow-y-wrapper
  background: linear-gradient(to top, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0) 30px), linear-gradient(to bottom, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0) 30px)
  background-repeat: no-repeat

.overflow-shadow-y-wrapper > .overflow-shadow-y
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 150px), linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 150px)
  background-repeat: no-repeat
  max-height: none
  min-height: 100%
