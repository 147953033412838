.map
  &-control
    box-shadow: $map-control-box-shadow
    border-radius: $map-control-border-radius
    background-color: $map-control-bg
    margin: $map-control-spacing

    .list-group
      .list-group-item
        &:first-child
          border-radius: $map-control-border-radius $map-control-border-radius 0 0
        &:last-child
          border-radius: 0 0 $map-control-border-radius $map-control-border-radius

  &-btn
    min-width: $map-btn-width
    height: $map-btn-height
    border: $map-btn-border-width
    user-select: none
    cursor: pointer
    outline: none

  &-dropdown
    .dropdown-toggle
      box-shadow: $map-control-box-shadow
      border-radius: $map-control-border-radius
      background-color: $map-control-bg
      margin: $map-control-spacing
      color: black
      user-select: none
      font-size: 18px
      font-weight: 500
      background-color: white
      padding: 8px 17px
      z-index: 10010
      position: relative

    .dropdown-menu
      box-shadow: $map-control-box-shadow
      border-radius: $map-control-border-radius
      background-color: $map-control-bg
      border: none
